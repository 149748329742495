import React, { useEffect } from "react";

import Analytics from "analytics";

import { oneSignalLogin, oneSignalLogout, runOneSignal } from "./runOneSignal";

import { Router, Routes, Route, Loadable } from "@discoverr/web/Router";
import { Bootstrap } from "@discoverr/web/Bootstrap";
import {
  SpaceAuthLoginPage,
  useSpaceAuthStore,
} from "@discoverr/web/SpaceAuth";
import { useNavigate } from "@discoverr/web/Router";
import { getTheme, ThemeType } from "@discoverr/web/themeOld";
import DAN from "@discoverr/web/languages/da";
import ENU from "@discoverr/web/languages/en";
import SVE from "@discoverr/web/languages/se";
import daDate from "date-fns/locale/da";
import { daDK } from "@mui/x-date-pickers/locales/daDK";
import { useServiceItemStore } from "stores/serviceItemShoppingCart";
import { Page403 } from "@discoverr/web/art/pages";
import {
  spaceTranslate,
  useSpaceTranslation,
} from "stores/useTranslationStore";
import { useNativeBridge } from "stores/nativeBridge";

const mixpanelPlugin = require("@analytics/mixpanel").default;

const loginView = require("./assets/login-image.png");

const analytics =
  process.env["REACT_APP_ENV"] == "prod"
    ? Analytics({
        app: "nc-nielsen",
        plugins: [
          mixpanelPlugin({
            token: "06d9276705ef4f3c209529ac58541947",
            pageEvent: "DISC: Page Viewed",
          }),
        ],
      })
    : undefined;

// Constants
const theme = getTheme(
  ThemeType.Light,
  {
    main: "#BF0A30",
    contrastText: "#fff",
  },
  {
    main: "#3C4148",
  },
  undefined,
  undefined,
  undefined,
  [daDK]
);

const languages = {
  DAN,
  ENU,
  SVE,
} as const;

// Views
const LoadableApp = Loadable(React.lazy(() => import("./views/App")));
const LoadableDashboard = Loadable(
  React.lazy(() => import("./views/Dashboard"))
);

// Components
const Login = () => {
  const navigate = useNavigate();
  const handleLoginSuccess = () => {
    useServiceItemStore.getState().resetItems();
    navigate("/");
  };

  return (
    <SpaceAuthLoginPage
      title="NCN Group Portal"
      subtitle={
        process.env["REACT_APP_ENV"] !== "prod"
          ? "Log på den interne platform (test)"
          : "Log på den interne platform"
      }
      image={loginView}
      onSuccess={handleLoginSuccess}
      onEnvToggle={useNativeBridge.getState().toggleEnv}
      usernameLabel={spaceTranslate("Initialer")}
    />
  );
};

//
export const Root = () => {
  const { language } = useSpaceTranslation();

  useEffect(() => {
    let authSub: (() => void) | null = null;
    runOneSignal();
    const authId = useSpaceAuthStore.getState().authId;
    if (authId) {
      oneSignalLogin(authId);
      useNativeBridge.getState().setAuthId(authId);
    }

    authSub = useSpaceAuthStore.subscribe((current, prev) => {
      if (current.authId !== prev.authId) {
        if (current.authId) {
          oneSignalLogin(current.authId);

          useNativeBridge.getState().setAuthId(current.authId);
        } else {
          oneSignalLogout();
          useNativeBridge.getState().setAuthId(null);
        }
      }
    });
    return () => {
      if (authSub) {
        authSub();
      }
    };
  }, []);

  return (
    <Bootstrap
      titleTemplate="N.C. Nielsen - %s"
      language={languages[(language ?? "DAN") as "DAN" | "ENU" | "SVE"]}
      dateFnsLocale={daDate}
      theme={theme}
      analyticsInstance={analytics}
      analyticsIdentificationTraitKeys={{ $name: "name" }}
    >
      <Router>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/dashboard/*" element={<LoadableDashboard />} />
          <Route path="/*" element={<LoadableApp />} />
          <Route path="/403" element={<Page403 />} />
        </Routes>
      </Router>
    </Bootstrap>
  );
};

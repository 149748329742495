import React from "react";
import axios from "axios";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { Root } from "./Root";

import { initBackend } from "@discoverr/web/backend";

LicenseInfo.setLicenseKey(
  "6e9ddeb2fb16eb1c66df54d530942283Tz03NTg0NyxFPTE3Mjc5NDEwNDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const backend = axios.create({
  baseURL: process.env["REACT_APP_API"] as string,
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
initBackend(backend);

// ReactDOM.render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

const container = document.getElementById("root");
const root = createRoot(container!);

// root.render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>
// );
root.render(<Root />);
